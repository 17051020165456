import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { get } from 'lodash'
import Helmet from 'react-helmet'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import HeroImage from '../components/contentful/hero-image'
import { usStates, buildBreadcrumbsItem } from '../helpers'
import axios from 'axios';
import qs from 'qs';
import styles from '../scss/page.scss'

class ContactUsPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Contact Us | Honest Paws®'
    this.pageUrl = this.props.location.href
    this.seo = {
      title: title,
      meta: {
        title: title
      },
      children: [
        <link rel="canonical" href={ this.pageUrl } key='canonical-url' />
      ]
    }
    
    this.breadcrumbs = [
      buildBreadcrumbsItem('Contact Us', this.props.location.href)
    ]
    this.state = {
      name: '',
      email: '',
      message: '',
      errors: {}
    }
    
    this.address = this.props.data.site.siteMetadata.publisher.address
    this.phone = this.props.data.site.siteMetadata.publisher.phone
    this.email = this.props.data.site.siteMetadata.publisher.emails.contact
    
    this.handleInputChange = this.handleInputChange.bind(this)
    this.submit = this.submit.bind(this)
  }
  
  /**
   * Handles inputs changes
   * @param  {Event} e
   * @return {void}
   */
  handleInputChange(e) {
    const target = e.currentTarget
    const value = target.value
    const stateProp = target.name
    this.setState({
      [stateProp]: value
    })
  }
  
  /**
   * Submits the form
   * @param  {Event} e
   * @return {void}
   */
  submit(e) {
    e.preventDefault()
    const form = e.currentTarget
    this.setState({
      errors: {},
      submitting: true
    }, () => {
      let errors = {}
      const requiredFields = [
        'name',
        'email',
        'message'
      ]
      requiredFields.forEach((field) => {
        if (!this.state[field]) {
          errors[field] = true
        }
      })
      if (Object.keys(errors).length) {    
        this.setState({
          errors, 
          submitting: false
        })
      } else {
        const params = {}
        for (const element of form.elements) {
          if (element.name) {
            params[element.name] = element.value
          }            
        }
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(params),
          url: form.action,
        };
        axios(options).then(function (response) {
          alert('We will contact you soon! Thanks.')
        })
        .catch(function (error) {
          alert('An error occurred. Please contact our support.')
        });
      }
    })
  }
  
  
  render() {
    return (
      <Layout containerClassName={`page page-contact-us`} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden" location={this.props.location} >
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <section className="page-title-and-summary page-header text-center px-4">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h1 className="mb-0">We would Love To Hear From You</h1>
              <div className="page-summary mt-3">
                Simply fill out the form below, click submit and a REAL HUMAN will be in touch with you soon.
                Keep in mind we are unable to answer individual animal health questions. Please consult your veterinarian
                for specific medical advice for your beloved pet.
              </div>
            </div>
          </div>
        </section>
        <section className="contact-us-form">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 py-6 px-4 px-lg-6 form-container text-center text-lg-left">
                <h2 className="text-primary mb-0">Get In Touch</h2>
                <p className="mb-3 mt-md-4 mb-md-5 section-subtitle">
                  Fill out the form and we will get in contact as soon as we can.
                </p>
                <form onSubmit={this.submit} name="form-contact-us" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
                  <input type="hidden" name="form-name" value="form-contact-us" />
                  <input type="text" name="bot-field" className="d-none" /> {/* Input for preventing bots  */}                  
                  <input type="hidden" name="subject" value="New form submissions on &quot;Contact Us&quot;" />           
                  <div className="row">
                    <div className="col-12 form-group">
                      <label htmlFor="name">Name*</label>
                      <input type="text" className="form-control" id="name" name="name" value={ this.state.name } onChange={this.handleInputChange} required/>
                      <small className={ `form-text text-danger ${this.state.errors.name ? '' : 'd-none'}` }>Please, enter your name.</small>
                    </div>
                    <div className="col-12 form-group">
                      <label htmlFor="email">Email*</label>
                      <input type="email" className="form-control" id="email" name="email" value={ this.state.email } onChange={this.handleInputChange} required/>
                      <small className={ `form-text text-danger ${this.state.errors.email ? '' : 'd-none'}` }>Please, enter your email.</small>
                    </div>
                    <div className="col-12 form-group">
                      <label htmlFor="message">Message*</label>
                      <textarea className="form-control" id="message" name="message" value={ this.state.message } onChange={this.handleInputChange} required>
                      </textarea>
                      <small className={ `form-text text-danger ${this.state.errors.message ? '' : 'd-none'}` }>Please, enter your message.</small>                      
                    </div>
                    <div className="col-12 mt-4">
                      <button type="submit" className="btn btn-primary" disabled={this.state.submitting}>Submit</button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-12 col-lg-6 py-6 px-4 px-lg-6 text-center text-lg-left">
                <h2 className="text-primary mb-0">Connect With Us</h2>
                <p className="mb-3 mt-md-4 mb-md-5 section-subtitle">
                  For any support questions email us at:
                  <br />
                  <a href={`mailto:${this.email}`} target="_blank">{this.email}</a>
                </p>
                <p className="mb-3 mt-md-4 mb-md-5 section-subtitle">
                  <span className="text-primary font-weight-bold">Business address:</span>
                  <br />
                  { `${this.address.streetAddress},` }
                  <br className="d-none d-lg-block" />
                  { `${this.address.city}, ${this.address.addressLocality} ${this.address.zip}` }
                </p>
                <p className="mb-3 mt-md-4 mb-md-5 section-subtitle">
                  <span className="text-primary font-weight-bold">Mailing address:</span>
                  <br />
                  { `${this.address.streetAddress},` }
                  <br className="d-none d-lg-block" />
                  { `${this.address.city}, ${this.address.addressLocality} ${this.address.zip}` }
                </p>
                <p className="mb-3 mt-md-4 mb-md-5 section-subtitle">
                  <span className="text-primary font-weight-bold">Telephone Number:</span>
                  <br />
                  <a href={`tel:${this.phone}`}>{this.phone}</a>
                  <br />
                  <span className="font-12 font-italic d-block mt-2">* Our phone number can be used for both calls and text messages. If you reach out for support via text and no longer wish to receive text messages from us, you can opt out at any time by replying 'STOP'</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        
      </Layout>
    )
  }
}

export default ContactUsPage

export const ContactUsPagePageQuery = graphql`
  query ContactUsPagePageQuery {
    site {
      siteMetadata {
        title
        siteUrl
        publisher {
          address {
            line_1
            line_2 
            streetAddress   
            city
            addressLocality
            state
            zip
          }      
          emails {
            contact
          }
          phone
        }
      }
    }    
  }
`